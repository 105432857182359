﻿import Toaster from "../components/Toaster";

function getToastContent(reason) {
  if (!reason.response) return { title: "Error occurred", body: "An unknown error occurred" };

  // problem details?
  const detail = reason.response?.data?.detail;
  const title = reason.response?.data?.title;

  switch (reason.response.status) {
    case 403:
      return {
        title: title ?? "Forbidden",
        body: detail ?? "You do not have permissions to perform this operation",
      };
    case 404:
      return {
        title: title ?? "Not found",
        body: detail ?? "The requested resource was not found",
      };
    default:
      return {
        title: title ?? `Status code ${reason.response.status}`,
        body: detail ?? "Unable to perform this operation",
      };
  }
}

export function handleAxiosError(reason?: any) {
  const content = getToastContent(reason);
  Toaster.Toast({
    body: content.body,
    subtitle: "",
    title: content.title,
    colorScheme: "warning",
  });
}

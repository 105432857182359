﻿import axios from "axios";
import eventBus from "../script/utils/event-bus";
import CheckoutBasket, { BasketUpdatedEvent } from "./components/checkout-basket";
import { handleAxiosError } from "./http/axios-handlers";
import NatchGtm, { EventNamesSchema, NatchOsDataLayerProduct } from "natch-gtm4";

const $body = $("body");

eventBus.on("basket-filled", (_) => {
  $(".js-weeklink").addClass("nt-basket-filled");
});

$body.on("submit", "form.js-add-to-basket", (e) => {
  e.preventDefault();
  e.stopPropagation();

  const $form = $(e.currentTarget);
  $form.removeClass("nt-form-success");

  const jsonString = $form.attr("data-datalayer-cart-item");
  const productDataLayerFields = (jsonString ? JSON.parse(jsonString) : null) as NatchOsDataLayerProduct;
  if (productDataLayerFields) {
    const listElement = $form.closest("[data-datalayer-list-name]");
    const productDataLayerListName = listElement?.attr("data-datalayer-list-name") || "";
    const productDataLayerListId = listElement?.attr("data-datalayer-list-id") || "";
    productDataLayerFields.list_name = productDataLayerListName;
    productDataLayerFields.list_id = productDataLayerListId;
  }

  // look for labeling fields on page:
  const pid = "" + $form.find(`input[name="pid"]`).val();
  const labelingProductCodes: { [id: string]: string } = {};
  labelingProductCodes[pid] = $(`#customer-code-${pid}`).val() as string;
  const labelingSalesPrices: { [id: string]: string } = {};
  labelingSalesPrices[pid] = $(`#customer-price-${pid}`).val() as string;
  const labelingBarCodes: { [id: string]: string } = {};
  labelingBarCodes[pid] = $(`#customer-barcode-${pid}`).val() as string;

  const productCode = $form.data("productCode") as string;
  const $children = $form
    .parents(".js-search-for-mixed-cart-children")
    .find(`[data-mixed-cart-parent-product-code="${productCode}"]`);
  $children.each((_, child) => {
    const $child = $(child);
    const childProductId = $child.data("productId") as string;
    labelingProductCodes[childProductId] = $child.find(`#customer-code-${childProductId}`).val() as string;
    labelingSalesPrices[childProductId] = $child.find(`#customer-price-${childProductId}`).val() as string;
    labelingBarCodes[childProductId] = $child.find(`#customer-barcode-${childProductId}`).val() as string;
  });

  let postUrl = "";
  let postData: any = {};
  if ("URLSearchParams" in window) {
    const searchParams = new URLSearchParams($form.serialize());

    if (!!labelingProductCodes) {
      searchParams.set("labelingProductCodes", encodeURIComponent(JSON.stringify(labelingProductCodes)));
    }
    if (!!labelingSalesPrices) {
      searchParams.set("labelingSalesPrices", encodeURIComponent(JSON.stringify(labelingSalesPrices)));
    }
    if (!!labelingBarCodes) {
      searchParams.set("labelingBarCodes", encodeURIComponent(JSON.stringify(labelingBarCodes)));
    }

    const quantity = searchParams.get("quantity");

    // update quantity field with moq, when empty
    if (quantity === "") {
      const $quantity = $form.find(`input[name="quantity"]`);
      const moq: number = $quantity.data("moq") as number;
      const o1size: number = $quantity.data("o1size") as number;
      searchParams.set("quantity", (moq / o1size).toString());
    }

    searchParams.set("attributionListItemId", productDataLayerFields?.list_id + "");
    searchParams.set("attributionListItemName", productDataLayerFields?.list_name + "");

    postUrl = $form.attr("action") + "";
    searchParams.forEach((value, key) => {
      postData[key] = value;
    });
  }

  // see CartController.cs
  axios
    .post(postUrl, postData, { headers: { Accept: "application/json" } })
    .then((response) => {
      eventBus.emit("basket-updated");

      if (productDataLayerFields !== null) {
        // quantityAdded = number of items (plants/carts)
        // unitPrice = per item (plants/carts)
        const natchGtm = new NatchGtm(EventNamesSchema.OfficialGA4);
        productDataLayerFields._quantity = response.data.quantityAdded;
        productDataLayerFields._price = response.data.unitPrice;
        console.log("Calling natchGtm.trackAddToCart", productDataLayerFields, response.data.totalPrice);
        natchGtm.trackAddToCart(productDataLayerFields, response.data.totalPrice);
      }

      $form.addClass("nt-form-success");
      setTimeout(() => {
        $form.removeClass("nt-form-success");
      }, 4000);
    })
    .catch(handleAxiosError);
});

$body.on("click", ".js-basket-line-remove", (e) => {
  e.preventDefault();
  const $this = $(e.currentTarget);
  const lineId = $this.data("line-id") as number;
  const basketId = $this.data("basket-id") as number;
  const url = $this.data("action-url") as string;
  if ((lineId > 0 && basketId > 0) === false) {
    return;
  }
  // get GTM datalayer data in a safe way
  const jsonString = $this.attr("data-datalayer-cart-item");
  const productDataLayerFields = jsonString ? JSON.parse(jsonString) : null;

  // see CartController.cs
  const postUrl = `${url}?basketId=${basketId}&basketLineId=${lineId}`;
  console.log(postUrl);
  axios
    .post(postUrl)
    .then((response) => {
      const updateResponse = response.data as BasketUpdatedEvent;
      CheckoutBasket.update(updateResponse.updatedBasket);
      if (productDataLayerFields !== null) {
        const natchGtm = new NatchGtm(EventNamesSchema.OfficialGA4);
        // TODO add price, quantity, eventValue should be price*quantity?
        console.log("Calling natchGtm.trackRemoveFromCart", productDataLayerFields);
        natchGtm.trackRemoveFromCart(productDataLayerFields, productDataLayerFields._price);
      }
    })
    .catch(handleAxiosError);
});

// toggle line comment visibility :
$body.on("click", ".js-toggle-basket-line-comment", (e) => {
  e.preventDefault();
  const $this = $(e.currentTarget);
  const lineId = $this.data("line-id") as number;
  $this.parent().siblings(`.js-basket-col-comment[data-basket-line-id="${lineId}"]`).children().toggle();
});

// change line comment :
$body.on("input", ".js-basket-line-comment", (e) => {
  const $this = $(e.currentTarget);
  $this.removeClass("border-success");
  $this.addClass("border-warning");
});

$body.on("change", ".js-basket-line-comment", (e) => {
  e.preventDefault();
  const $this = $(e.currentTarget);
  const lineId = $this.data("line-id") as number;
  const basketId = $this.data("basket-id") as number;
  const url = $this.data("action-url") as string;
  if ((lineId > 0 && basketId > 0) === false) {
    return;
  }
  const comment = $this.val();
  axios
    .post(`${url}?basketId=${basketId}&lineId=${lineId}&comment=${comment}`)
    .then((data) => {
      $this.removeClass("border-warning");
      $this.addClass("border-success");
      setTimeout(function () {
        $this.removeClass("border-success");
      }, 2000);
      const hasComment = (comment + "").trim().length > 0;
      $(`.js-toggle-basket-line-comment[data-line-id="${lineId}"] .js-comment-badge`).toggle(hasComment);
    })
    .catch(handleAxiosError);
});

// change line labeling :
$body.on("input", ".js-change-basket-line-labeling", (e) => {
  const $this = $(e.currentTarget);
  $this.removeClass("border-success");
  $this.addClass("border-warning");
});

$body.on("change", ".js-change-basket-line-labeling", (e) => {
  e.preventDefault();
  const $this = $(e.currentTarget);
  const lineId = $this.data("line-id") as number;
  const basketId = $this.data("basket-id") as number;
  const url = $this.data("action-url") as string;
  if ((lineId > 0 && basketId > 0) === false) {
    return;
  }
  const value = $this.val();
  axios
    .post(`${url}?basketId=${basketId}&lineId=${lineId}&value=${value}`)
    .then((response) => {
      const data = response.data;
      $this.val(data);
      $this.removeClass("border-warning");
      $this.addClass("border-success");
      setTimeout(function () {
        $this.removeClass("border-success");
      }, 2000);
    })
    .catch(handleAxiosError);
});

// change basket comment :
$body.on("input", ".js-basket-comment-change", (e) => {
  const $this = $(e.currentTarget);
  $this.removeClass("border-success");
  $this.addClass("border-warning");
});

$body.on("change", ".js-basket-comment-change", (e) => {
  e.preventDefault();
  const $this = $(e.currentTarget);
  const basketId = $this.data("basket-id") as number;
  const url = $this.data("action-url") as string;
  if (basketId > 0 === false) {
    return;
  }
  const comment = $this.val();
  axios
    .post(`${url}?basketId=${basketId}&comment=${comment}`)
    .then((data) => {
      $this.removeClass("border-warning");
      $this.addClass("border-success");
      setTimeout(function () {
        $this.removeClass("border-success");
      }, 2000);
    })
    .catch(handleAxiosError);
});

﻿import $$ from "../utils/double-dollar";

$$(".js-collapse-eye", (el) => {
  const hel = el as HTMLElement;
  if (!hel) return;

  const id = hel.dataset?.followsCollapse;
  if (!id) return;

  const collapsible = document.getElementById(id);
  if (!collapsible) return;

  collapsible.addEventListener("hide.bs.collapse", (event) => {
    if (event.eventPhase === Event.BUBBLING_PHASE) return;
    hel.classList.remove("open");
    hel.classList.add("closed");
    if (hel.classList.contains("changed")) {
      hel.classList.remove("changed");
      hel.classList.add("initial");
    } else {
      hel.classList.remove("initial");
      hel.classList.add("changed");
    }
  });

  collapsible.addEventListener("show.bs.collapse", (event) => {
    if (event.eventPhase === Event.BUBBLING_PHASE) return;
    hel.classList.remove("closed");
    hel.classList.add("open");
    if (hel.classList.contains("changed")) {
      hel.classList.remove("changed");
      hel.classList.add("initial");
    } else {
      hel.classList.remove("initial");
      hel.classList.add("changed");
    }
  });
});

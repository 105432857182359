﻿import $$ from "../utils/double-dollar";
import { Collapse } from "bootstrap";
// import Global from "../utils/multilang";

type PickableWeek = {
  id: number;
  parentId: number;
  week: number;
  year: number;
  weekStartDate: string;
  weekStopDate: string;
};

class WeekPicker {
  public static init() {
    $(".nt-week-picker").each(function (index) {
      const $input = $(this);

      const catalogList = $input.data("source") as PickableWeek[];
      const parentCatalogId = $input.data("parent") as number;

      if (!catalogList) {
        return;
      }

      const parentCatalogList = catalogList.filter((c) => c.parentId === parentCatalogId);
      if (parentCatalogList.length === 0) {
        return;
      }

      const firstDate = new Date(parentCatalogList[0].weekStartDate);
      const lastDate = new Date(parentCatalogList[parentCatalogList.length - 1].weekStopDate);

      const languageCode = $("html").attr("lang") || "nl";
      const options: DatepickerOptions = {
        language: languageCode,
        title: `week-picker-${parentCatalogId}`, // will be hidden by CSS, for internal use
        // orientation: "bottom",
        // firstDay: 1,
        weekStart: 1,
        startDate: firstDate,
        endDate: lastDate,
        calendarWeeks: true,
        maxViewMode: 2,
        beforeShowDay: (date) => {
          const currentTime = date.getTime();

          for (const catalog of parentCatalogList) {
            const catalogStartTime = new Date(catalog.weekStartDate).getTime();
            const catalogStopTime = new Date(catalog.weekStopDate).getTime();

            if (catalogStartTime <= currentTime && currentTime <= catalogStopTime) {
              return true;
            }
          }

          return false;
        },
      };
      $input
        .datepicker(options)
        .on("show", (e) => {
          WeekPicker.setCustomStyles($input);
          WeekPicker.showSelectedWeek($input);
        })
        .on("changeDate", (e) => {
          WeekPicker.setCustomStyles($input);
          WeekPicker.showSelectedWeek($input);

          const selectedTime = e.date.getTime();

          const selectedCatalog = catalogList.find((catalog) => {
            const catalogStartTime = new Date(catalog.weekStartDate).getTime();
            const catalogStopTime = new Date(catalog.weekStopDate).getTime();

            if (catalogStartTime <= selectedTime && selectedTime <= catalogStopTime) {
              return catalog;
            }
          });

          if (!selectedCatalog) return;

          const redirectUrl = $input.data("redirect") as string;
          const redirectParam = $input.data("redirect-param") ?? "cat";
          const baseUrl = redirectUrl.indexOf("?") >= 0 ? redirectUrl + "&" : redirectUrl + "?";
          document.location.href = `${baseUrl}${redirectParam}=${selectedCatalog.id}`;
        })
        .on("changeMonth", (e) => {
          WeekPicker.setCustomStyles($input);
          WeekPicker.showSelectedWeek($input);
        })
        .on("changeYear", (e) => {
          WeekPicker.setCustomStyles($input);
          WeekPicker.showSelectedWeek($input);
        });

      // when week-picker is loaded as a DIV, timeout for rendering time
      setTimeout(() => {
        WeekPicker.setCustomStyles($input);
        WeekPicker.showSelectedWeek($input);
      }, 1);

      // set datepicker to first possible date (could be different month) + remove active class
      const currentCatalogId = $input.data("current") as number;
      const currentDateString = catalogList.filter((c) => c.id == currentCatalogId).first()?.weekStartDate;
      const currentDate = !!currentDateString ? new Date(currentDateString) : firstDate;
      $input.datepicker("update", currentDate);
    });
  }

  private static getActiveWeekPicker(parentCatalogId) {
    const selectorText = `week-picker-${parentCatalogId}`;
    const selector = "th:contains('" + selectorText + "')";
    return $(selector).closest(".datepicker");
  }

  private static setCustomStyles($input) {
    const parentCatalogId = $input.data("parent");
    const $activeWeekPicker = WeekPicker.getActiveWeekPicker(parentCatalogId);

    $activeWeekPicker.addClass("nt-week-picker");

    setTimeout(function () {
      $activeWeekPicker.find(".active.day").removeClass("active");
      $activeWeekPicker.find(".new.day").removeClass("new");
      $activeWeekPicker.find(".old.day").removeClass("old");
    }, 1);
  }

  private static showSelectedWeek($input) {
    const catalogList = $input.data("source");
    const currentCatalogId = $input.data("current");
    const parentCatalogId = $input.data("parent");

    if (typeof currentCatalogId !== "undefined") {
      const currentCatalog = catalogList.find((catalog) => {
        return catalog.id === currentCatalogId && catalog.parentId === parentCatalogId;
      });

      // show selected catalog in datepicker
      if (typeof currentCatalog !== "undefined" && currentCatalog !== null) {
        const $activeWeekPicker = WeekPicker.getActiveWeekPicker(parentCatalogId);

        const weekElement = $activeWeekPicker.find("td.cw:contains('" + currentCatalog.week + "')");

        if (weekElement.length > 0) {
          weekElement.closest("tr").find("td:not('.cw'):not('.disabled')").addClass("nt-selected");
        }
      }
    }
  }
}

WeekPicker.init();

$("body").on("click", (e) => {
  $$(".js-week-picker.collapse.show", (el) => {
    const collapse = new Collapse(el, { toggle: false });
    collapse.hide();
  });
});

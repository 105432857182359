export enum MemoizeCacheType {
  Page = 1,
  Session = 2,
}

interface MemoizeCache<T> {
  containsKey(key: string): boolean;

  add(key: string, value: T): void;

  get(key: string): T;
}

class PageCache<T> implements MemoizeCache<T> {
  private cache = {};

  add(key: string, value: T): void {
    this.cache[key] = value;
  }

  containsKey(key: string): boolean {
    return key in this.cache;
  }

  get(key: string): T {
    return this.cache[key];
  }
}

class SessionCache<T> implements MemoizeCache<T> {
  add(key: string, value: T): void {
    const json = JSON.stringify(value);
    sessionStorage.setItem(key, json);
  }

  containsKey(key: string): boolean {
    return sessionStorage.getItem(key) !== null;
  }

  get(key: string): T {
    const json = sessionStorage.getItem(key) ?? "{}";
    return JSON.parse(json) as T;
  }
}

function createCacheInstance<T>(cacheType: MemoizeCacheType) {
  return cacheType === MemoizeCacheType.Session ? new SessionCache<T>() : new PageCache<T>();
}

export function memoize(fn: (arg: any) => any, cacheType: MemoizeCacheType = MemoizeCacheType.Page) {
  const cache = createCacheInstance<any>(cacheType);
  const fnHash = hashCode(fn.toString());
  return (...args) => {
    const cacheKey = `memoize//${fnHash}/${args[0]}`;
    if (cacheKey in cache) {
      log("Fetching from cache", cacheKey);
      return cache[cacheKey];
    } else {
      log("Calculating result", cacheKey);
      let result = fn(cacheKey);
      if (result !== undefined) cache[cacheKey] = result;
      return result;
    }
  };
}

export function memoizeAsync<T>(
  fn: (arg1: string, arg2: string) => Promise<T>,
  cacheType: MemoizeCacheType = MemoizeCacheType.Page
) {
  const cache = createCacheInstance<T>(cacheType);
  const fnHash = hashCode(fn.toString());
  return async (a: string, b: string) => {
    const cacheKey = `memoize//${fnHash}/${a}//${b}`;
    if (cacheKey in cache) {
      log("Fetching from cache for key =", cacheKey);
      const result = cache[cacheKey];
      log(result);
      return result;
    } else {
      log("Key =", cacheKey, "not found in cache =", cache);
      log("Calculating result", cacheKey);
      let result = await fn(a, b);
      if (result !== undefined) cache[cacheKey] = result;
      return result;
    }
  };
}

const log = (...args) => {
  console.log(...args);
};

/**
 * Returns a hash code from a string
 * @param  {String} str The string to hash.
 * @return {Number}    A 32bit integer
 * @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
function hashCode(str) {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    let chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

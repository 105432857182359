﻿import axios from "axios";
import { handleAxiosError } from "../http/axios-handlers";
import { Tooltip } from "bootstrap";

export interface UpdatedBasketLine {
  lineId: number;
  lineGuid: string;
  quantity: number;
  trolleyFillFactor: number;
  lineAmount: number;
  formattedTrolleyFillFactor: string;
  formattedLineAmount: string;
}

export interface RemovedBasketLine {
  lineId: number;
  lineGuid: string;
}

export interface UpdatedBasket {
  basketId: number;
  updatedLines: UpdatedBasketLine[];
  removedLines: RemovedBasketLine[];
  totalTrolleyFillFactor: number;
  totalAmount: number;
  totalTrolleyData: TrolleyData;
  formattedTotalAmount: string;
  duplicateProductCodesWarning: string;
}

export interface BasketUpdatedEvent {
  updatedBasket: UpdatedBasket;
}

export interface TrolleyData {
  trolleyCount: number;
  formattedTrolleyCount: string;
  showFullTrolleyIcon: boolean;
  showPartialTrolleyIcon: boolean;
  partialTrolleyIconClass: string;
}

export default class CheckoutBasket {
  static update(updatedBasket: UpdatedBasket) {
    $(`.js-updated-basket--total-amount[data-basket-id="${updatedBasket.basketId}"]`).text(
      updatedBasket.formattedTotalAmount
    );

    const $totalTrolleyFillFactor = $(
      `.js-updated-basket--total-trolley-fill-factor[data-basket-id="${updatedBasket.basketId}"]`
    );
    $totalTrolleyFillFactor.find("span").text(updatedBasket.totalTrolleyData.formattedTrolleyCount);

    const $trolleyIconFull = $totalTrolleyFillFactor.find(".js-trolley-icon--full");
    $trolleyIconFull.toggleClass("d-none", !updatedBasket.totalTrolleyData.showFullTrolleyIcon);

    const $trolleyIconPartial = $totalTrolleyFillFactor.find(".js-trolley-icon--partial");
    $trolleyIconPartial.toggleClass("d-none", !updatedBasket.totalTrolleyData.showPartialTrolleyIcon);
    $trolleyIconPartial.removeClass(
      "nt-trolley-icon--partial-25 nt-trolley-icon--partial-50 nt-trolley-icon--partial-75 nt-trolley-icon--partial-100"
    );
    $trolleyIconPartial.addClass(updatedBasket.totalTrolleyData.partialTrolleyIconClass);

    for (const updatedLine of updatedBasket.updatedLines) {
      $(`.js-updated-basket--line-amount[data-basket-line-id="${updatedLine.lineId}"]`).text(
        updatedLine.formattedLineAmount
      );

      const $lineTrolleyFillFactor = $(
        `.js-updated-basket--line-trolley-fill-factor[data-basket-line-id="${updatedLine.lineId}"]`
      );
      $lineTrolleyFillFactor.find("span").text(updatedLine.formattedTrolleyFillFactor);

      this.updateNurtureFeedback(updatedLine.lineId);
    }

    for (const removedLine of updatedBasket.removedLines) {
      $(`.js-updated-basket--basket-line[data-basket-line-id="${removedLine.lineId}"]`).remove();
    }

    if (updatedBasket.removedLines.length > 0) {
      const $dupesWarning = $(".js-checkout-duplicates-warning");
      $dupesWarning.html(updatedBasket.duplicateProductCodesWarning);
      if (!updatedBasket.duplicateProductCodesWarning) {
        $dupesWarning.hide();
      }
    }
  }

  static updateNurtureFeedback(lineId: number) {
    const nurtureFeedbackSelector = `.js-updated-basket--line-trolley-nurture-feedback-${lineId}`;
    const $nurtureFeedback = $(nurtureFeedbackSelector);

    const refreshUrl = $nurtureFeedback.data("refresh-url");
    axios
      .get(refreshUrl, { headers: { Accept: "application/json" } })
      .then((response) => {
        const nurtureFeedbackString = response.data as string;

        $nurtureFeedback.toggleClass("d-none", nurtureFeedbackString.length === 0);
        $nurtureFeedback.attr("aria-label", nurtureFeedbackString);

        const tooltipElement: HTMLElement = document.querySelector(nurtureFeedbackSelector) as HTMLElement;
        tooltipElement.title = nurtureFeedbackString;

        const tooltip = new Tooltip(tooltipElement);
        tooltip.hide();
      })
      .catch(handleAxiosError);
  }
}

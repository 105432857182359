﻿import axios from "axios";
import CheckoutBasket, { BasketUpdatedEvent } from "./checkout-basket";
import { handleAxiosError } from "../http/axios-handlers";
import { Tooltip } from "bootstrap";
import debounce from "lodash-es/debounce";
import $$ from "../utils/double-dollar";

export default class QuantitySelector {
  private static readonly dataAttributeLoaded = "data-quantity-selector-loaded";
  /**
   * A static tooltip, such that we will only show at most one on page.
   * @private
   */
  private static nurtureTooltip?: Tooltip;
  private $element: JQuery<Element>;
  private nurtureUrl?: string;
  private nurtureQuantity?: number;
  private nurtureTarget?: Element;

  private debouncedCheckNurture = debounce(() => {
    const url = this.nurtureUrl;
    const quantity = this.nurtureQuantity;
    const target = this.nurtureTarget;
    this.requestNurtureFeedback(url, quantity, target);
  }, 1000);

  private debouncedSave = debounce(() => {
    this.save();
  }, 1000);

  constructor(element: Element) {
    this.$element = $(element);

    // prevent normal/page submit of parent form
    this.$element.parent("form.js-submit-on-quantity-change").on("submit", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
    });

    this.$element.off("click", ".js-quantity-decrement");
    this.$element.on("click", ".js-quantity-decrement", (e) => this.decrement());

    this.$element.off("click", ".js-quantity-increment");
    this.$element.on("click", ".js-quantity-increment", (e) => this.increment());

    this.$element.off("change", 'input[name="quantity"]');
    this.$element.on("change", 'input[name="quantity"]', (e) => {
      this.save();
      this.checkNurtureForForm();
    });

    this.$element.off("input", 'input[name="quantity"]');
    this.$element.on("input", 'input[name="quantity"]', (e) => {
      this.debouncedSave();
      this.checkNurtureAfterQuantityChange();
    });

    element.setAttribute(QuantitySelector.dataAttributeLoaded, "true");
  }

  public get quantity(): number {
    const val: string = this.$element.find(`input[name="quantity"]`).val() as string;
    const qty: number = parseInt(val);
    return Number.isNaN(qty) ? 0 : qty;
  }

  private set quantity(value: number) {
    this.$element.find(`input[name="quantity"]`).val(value);
    this.$element.find(`input[name="quantity"]`).data("mult", value * this.o1size);
  }

  public get moq(): number {
    const val: string = this.$element.find(`input[name="quantity"]`).data("moq");
    return parseInt(val);
  }

  public get ioq(): number {
    const val: string = this.$element.find(`input[name="quantity"]`).data("ioq");
    return parseInt(val);
  }

  public get o1size(): number {
    const val: string = this.$element.find(`input[name="quantity"]`).data("o1size");
    return parseInt(val);
  }

  public get mult(): number {
    const val: string = this.$element.find(`input[name="quantity"]`).data("mult");
    return parseInt(val);
  }

  private get quantityElement(): HTMLElement | undefined {
    const $el = this.$element.find(`input[name="quantity"]`);
    if ($el.length == 0) return undefined;
    return $el.get(0);
  }

  public static isLoaded(element: Element) {
    const value = element.getAttribute(QuantitySelector.dataAttributeLoaded);
    return value === "true";
  }

  public static initPage(): void {
    QuantitySelector.hideNurtureTooltip();
    $$(".nt-product-quantity-selector", (el) => {
      if (QuantitySelector.isLoaded(el)) {
        return;
      }
      const _ = new QuantitySelector(el);
    });
  }

  public static initElement(element: Element): void {
    const $element = $(element);
    if ($element.hasClass(".nt-product-quantity-selector")) {
      new QuantitySelector(element);
      return;
    }

    $element.find(".nt-product-quantity-selector").each((index, el) => {
      new QuantitySelector(el);
    });
  }

  private static hideNurtureTooltip(): void {
    if (QuantitySelector.nurtureTooltip) {
      QuantitySelector.nurtureTooltip.hide();
    }
  }

  public decrement(): void {
    let q = this.quantity - this.ioq / this.o1size;
    if (q < this.moq / this.o1size) {
      q = this.moq / this.o1size;
    }

    this.quantity = q;
    this.checkNurtureAfterQuantityChange();
    this.save();
  }

  public increment(): void {
    let q = this.quantity + this.ioq / this.o1size;
    if (q < this.moq / this.o1size) {
      q = this.moq / this.o1size;
    }

    this.quantity = q;
    this.checkNurtureAfterQuantityChange();
    this.save();
  }

  public checkNurtureAfterQuantityChange(): void {
    const $form = this.$element.parent("form.js-add-to-basket");
    if (!$form || $form.length === 0) {
      return;
    }

    this.nurtureUrl = $form.data("nurture-url");
    this.nurtureQuantity = this.quantity;
    this.nurtureTarget = this.quantityElement;
    this.debouncedCheckNurture();
  }

  /**
   * Saves the quantity by sending request to API.
   * Only performed if form is marked to submit on change.
   */
  public save(): void {
    const $form = this.$element.parent("form.js-submit-on-quantity-change");
    if (!$form || $form.length === 0) {
      return;
    }

    const url = $form.attr("action") + "?" + $form.serialize();
    axios
      .get(url, { headers: { Accept: "application/json" } })
      .then((response) => {
        const updateResponse = response.data as BasketUpdatedEvent;
        CheckoutBasket.update(updateResponse.updatedBasket);
      })
      .catch(handleAxiosError);
  }

  /**
   * Checks whether a nurture messages should be shown to the user.
   * Only performed when the form is marked as 'add to basket'.
   */
  public checkNurtureForForm(): void {
    const $form = this.$element.parent("form.js-add-to-basket");
    if (!$form || $form.length === 0) {
      return;
    }

    const url = $form.data("nurture-url");
    this.requestNurtureFeedback(url, this.quantity, this.quantityElement);
  }

  private requestNurtureFeedback(url, quantity, target): void {
    axios
      .get(`${url}&quantity=${quantity}`)
      .then((response) => {
        const feedback = response.data;
        if (!feedback) {
          QuantitySelector.hideNurtureTooltip();
          return;
        }

        if (!target) return;

        this.setNurtureTooltip(target, feedback);
      })
      .catch(handleAxiosError);
  }

  private setNurtureTooltip(target, feedback): void {
    QuantitySelector.hideNurtureTooltip();

    const tooltip = new Tooltip(target, {
      title: feedback,
      trigger: "manual",
      customClass: "nt-tooltip--info",
    });

    tooltip.show();
    QuantitySelector.nurtureTooltip = tooltip;
  }
}

QuantitySelector.initPage();

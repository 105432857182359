import $ from "jquery";
import axios from "axios";
import CarbonIcons from "../utils/carbon-icons";
import Slider from "./slider";
import QuantitySelector from "./order-box";
import debounce, { DebouncedFunc } from "lodash-es/debounce";
import "../utils/array-extensions";

////import Forms from "../utils/forms";

type CacheEntry = {
  key: string;
  handler: DebouncedFunc<() => void>;
};

export class UpdatePanel {
  private static _cache: CacheEntry[] = [];
  private static lastUpdatedSelector = "";

  constructor() {
    window.addEventListener("popstate", (event): void => {
      // trigger reload
      document.location.href = document.location.href;
    });

    const $body = $("body");

    $body.on("click", ".js-update-panel-on-click", (e) => {
      // when used on a form submit button, the downside is that the form is not actually submitted
      // as a GET is performed and no JQuery validation is triggered
      const $target = $(e.currentTarget);
      const href = $target.attr("href") ?? UpdatePanel.getFormAndUrl($target)[1];
      if (!href) {
        return;
      }
      const selector = $target.data("update-panel-selector");
      const pushHistory = !!$target.data("update-panel-push-history");
      e.preventDefault();
      // e.stopPropagation(); // DO NOT do this as we need the event to bubble to ".js-check-onclick" handler
      UpdatePanel.loadPage(href, selector, pushHistory);
    });

    $body.on("input", ".js-update-panel-on-input input", (e) => {
      // set props on form to be handled when form is submitted, ie. a GET URL
      // this should trigger JQuery validation
      const $target = $(e.currentTarget);
      e.preventDefault();
      const strWait = $target.data("auto-submit-debounce");
      const wait = !!strWait ? parseInt(strWait) : 1000;
      const formId = $target.parents("form").attr("id") ?? "";
      const handler = UpdatePanel.getSingleMethod(
        formId,
        debounce(() => {
          const result = UpdatePanel.getFormAndUrl($target);
          const $form = result[0];
          const href = result[1];
          if (!href) {
            return;
          }
          const selector = $form.data("update-panel-selector");
          const pushHistory = !!$form.data("update-panel-push-history");
          UpdatePanel.loadPage(href, selector, pushHistory);
        }, wait)
      );
      handler();
    });

    $body.on("click", ".js-update-panel-on-submit", (e) => {
      // set props on form to be handled when form is submitted, ie. a GET URL
      // this should trigger JQuery validation
      const $clicked = $(e.currentTarget);
      const result = UpdatePanel.getFormAndUrl($clicked);
      const $form = result[0];
      const href = result[1];
      if (!!href) {
        $form.data("update-panel-href", href);
        const selector = $clicked.data("update-panel-selector");
        $form.data("update-panel-selector", selector);
        const pushHistory = !!$clicked.data("update-panel-push-history");
        $form.data("update-panel-push-history", pushHistory);
      }
    });

    $body.on("submit", "form", (e) => {
      const $form = $(e.target);
      const href = $form.data("update-panel-href");
      if (!href) {
        // proceed with normal submit
        // this is only triggered for forms with submit buttons
        // that have "js-update-panel-on-submit" class
        return;
      }
      const selector = $form.data("update-panel-selector");
      const pushHistory = !!$form.data("update-panel-push-history");
      e.preventDefault();
      e.stopPropagation();
      $form.data("update-panel-href", "");
      $form.data("update-panel-selector", "");
      $form.data("update-panel-push-history", "");
      UpdatePanel.loadPage(href, selector, pushHistory);
    });
  }

  private static getSingleMethod(key: string, handler) {
    if (this._cache.some((i) => i.key === key) === false) {
      console.log("create entry for", key);
      this._cache.push({ key, handler });
    }
    return this._cache.filter((i) => i.key === key)[0].handler;
  }

  private static getFormAndUrl($triggered: JQuery<HTMLElement>): Readonly<[JQuery<HTMLElement>, string?]> {
    const $form = $triggered.parents("form");
    if ($form.length === 0) {
      return [$form, undefined];
    }
    const action = $triggered.attr("formaction") ?? $form.prop("action");
    const queryString = $form.serialize();
    return [$form, `${action}/?${queryString}`];
  }

  private static loadPage(href: string, selector: string, push: boolean) {
    this.animatedScrollTo(".nt-product-search--main-pane", 20);

    // console.debug(`updating '${selector}' with content from ${href}`);
    axios.get(href).then((response) => {
      const html = response.data as string;
      const $html = $("<div/>").append(html);
      this.animatedReplaceOnPage($html, selector, href);
      if (push) {
        // add to navigation history
        history.pushState(null, document.title, href);
        UpdatePanel.lastUpdatedSelector = selector;
      }
    });
  }

  private static animatedScrollTo = (target: string, offset: number) => {
    let $target: any = $(target);
    const targetOffset = $target.offset().top - offset;
    $("html,body").animate({ scrollTop: targetOffset }, 800);
  };

  private static animatedReplaceOnPage = ($html: JQuery<HTMLElement>, selector: string, href: string) => {
    const parts = selector
      .split(",")
      .distinct()
      .filter((s) => !!s);
    for (const part of parts) {
      const $newResults = $html.find(part);
      const $pagePart = $(part);
      $pagePart.fadeOut({
        duration: "fast",
        done: () => {
          $pagePart.html($newResults.html() ?? "");
          $pagePart.fadeIn({
            duration: "fast",
          });

          console.debug(`updated '${part}' with content from ${href}`);

          ////Forms.revalidateForms();
          ////Forms.resetSubmitButtons();
          CarbonIcons.init();
          Slider.init();
          QuantitySelector.initPage();
        },
      });
    }

    // now also replace toasts
    $(".nt-toaster").html($html.find(".nt-toaster").html());
  };
}

new UpdatePanel();

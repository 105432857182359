// entry point for general site code / not for vue code
////import $ from "jquery";
import $$ from "./script/utils/double-dollar";
import { Button, Tooltip } from "bootstrap";
import NatchGtm, { EventNamesSchema } from "natch-gtm4";
import Slider from "./script/components/slider";
import Documents from "./script/pages/documents";
import "./script/http/set-axios-defaults";
import "./script/utils/carbon-icons";
import "./script/components/update-panel";
import "./script/components/auto-submits";
import "./script/components/collapse-eye";
import "./script/utils/default-actions";
import "./script/components/weeklink";
import "./script/basket-actions";
import "./script/components/order-box";
import "./script/components/week-picker";
import "./script/components/open-link-in-modal";
import "./script/components/search-autocomplete";

class App {
  static init() {
    console.info("🪴 App.init() is running");

    const natchGtm = new NatchGtm(EventNamesSchema.OfficialGA4);
    natchGtm.processPage();

    // initialize Bootstrap components
    $$(`[rel="tooltip"]`, (el) => new Tooltip(el));
    $$(`[data-bs-toggle="tooltip"]`, (el) => new Tooltip(el));
    $$(`[data-bs-toggle="button"]`, (el) => new Button(el));

    Documents.init();
    Slider.init();

    $["validator"].setDefaults({
      highlight: function (element) {
        $(element).closest(".form-control").addClass("is-invalid");
      },
      unhighlight: function (element) {
        $(element).closest(".form-control").removeClass("is-invalid");
      },
    });
  }
}

App.init();

import debounce from "lodash-es/debounce";

/**
 * Submit a form.
 * @param $elementInForm The element in a form to submit.
 * @param wait The number of milliseconds to delay the submitting of the form (default = 1000).
 */
export function submitForm($elementInForm, wait = 1000) {
  debounce(() => {
    const $form = $elementInForm.parents("form").first();
    if ($form.length === 0) return;
    const form = $form.get(0) as HTMLFormElement;
    if (!!form && form.requestSubmit) {
      form.requestSubmit();
    } else {
      $form.trigger("submit");
    }
  }, wait)();
}

$("body").on("click change", ".js-auto-submit", (e) => {
  const $this = $(e.currentTarget);
  e.preventDefault();
  $this.parents("form").trigger("submit");
});

$("body").on("input", ".js-auto-submit-on-input input", (e) => {
  const $this = $(e.currentTarget);
  e.preventDefault();
  const strWait = $this.data("auto-submit-debounce");
  const wait = !!strWait ? parseInt(strWait) : 1000;
  submitForm($this, wait);
});

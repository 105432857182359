import Swiper, { Navigation, Pagination } from "swiper";

export default class Slider {
  public static init(element: Element | undefined = undefined) {
    let bulletSwipers = (element ?? document).querySelectorAll("[data-nt-swiper='bullet']");
    for (let i = 0; i < bulletSwipers.length; i++) {
      const el = bulletSwipers[i] as HTMLElement;
      const $el = $(el);

      // display just before initializing the component, to prevent non-swiper elements
      $el.toggleClass("d-none", false);

      const swiper = new Swiper(el, {
        pagination: {
          el: ".js-bullet-swiper-pagination",
          clickable: true,
        },
        navigation: {
          prevEl: ".js-bullet-swiper-button--prev",
          nextEl: ".js-bullet-swiper-button--next",
        },
        breakpoints: Slider.getSwiperBreakpointOptions($el),
        watchOverflow: true,
        modules: [Navigation, Pagination],
      });

      const index = $el.find(".swiper-slide.js-auto-slide-here").index();
      swiper.slideTo(index, 1000);
    }

    let imageSwipers = (element ?? document).querySelectorAll("[data-nt-swiper='image']");
    for (let i = 0; i < imageSwipers.length; i++) {
      const el = imageSwipers[i] as HTMLElement;
      const $el = $(el);

      // display just before initializing the component, to prevent non-swiper elements
      $el.toggleClass("d-none", false);

      let imageList: string[] = [];

      $el
        .find(".swiper-slide")
        .find("img")
        .each(function () {
          imageList.push($(this).prop("src"));
        });

      const swiper = new Swiper(el, {
        spaceBetween: 30,
        pagination: {
          el: ".js-image-swiper-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return `<img src="${imageList[index]}" alt="" class="${className}"></img>`;
          },
        },
        breakpoints: Slider.getSwiperBreakpointOptions($el),
        watchOverflow: true,
      });
    }
  }

  private static getSwiperBreakpointOptions($el: JQuery<HTMLElement>): any {
    const setBreakpoints = $el.data("nt-swiper-breakpoints");

    if (setBreakpoints) {
      const initialColumns = $el.data("nt-swiper-breakpoints-col");

      if (initialColumns === 6) {
        return {
          0: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          576: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
          992: {
            slidesPerView: 4,
            spaceBetween: 16,
          },
          1200: {
            slidesPerView: 5,
            spaceBetween: 16,
          },
          1400: {
            slidesPerView: 6,
            spaceBetween: 16,
          },
        };
      }

      if (initialColumns === 4) {
        return {
          0: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        };
      }

      if (initialColumns === 3) {
        return {
          0: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        };
      }

      if (initialColumns === 2) {
        return {
          0: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
        };
      }
    }

    // return default
    return {
      0: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
    };
  }
}

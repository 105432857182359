﻿import $ from "jquery";
import CarbonIcons from "../utils/carbon-icons";

export default class Documents {
  public static init() {
    const allElements = document.querySelectorAll(".js-document-list");
    allElements.forEach((docCollapse) => {
      docCollapse?.addEventListener("show.bs.collapse", (event) => {
        if (!event.target) return;
        const target$ = $(event.target);
        const ajaxUrl = target$.attr("data-ajax-url");
        if (ajaxUrl) {
          target$.removeAttr("data-ajax-url");
          fetch(ajaxUrl)
            .then((r) => r.text())
            .then((h) => {
              target$.html(h);
              CarbonIcons.init();
            });
        }
      });

      docCollapse?.addEventListener("show.bs.offcanvas", (event) => {
        // for all canvas, later we might need to filter .js-document-product-catalog-canvas
        if (!event.target) return;
        const target$ = $(event.target);
        const ajaxUrl = target$.attr("data-ajax-url");
        console.log(ajaxUrl);
        if (ajaxUrl) {
          target$.removeAttr("data-ajax-url");
          fetch(ajaxUrl)
            .then((r) => r.text())
            .then((h) => {
              target$.find(".js-ajax-target").html(h);
              CarbonIcons.init();
            });
        }
      });
    });
  }
}

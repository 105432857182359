﻿import $ from "jquery";
import { Modal } from "bootstrap";
import axios from "axios";
import CarbonIcons from "../utils/carbon-icons";
import Slider from "./slider";
import QuantitySelector from "../components/order-box";
import MouseEventBase = JQuery.MouseEventBase;

declare var dataLayer: any[];
export default class OpenLinkInModal {
  constructor() {
    $("body").on("click", ".js-open-link-in-modal", (e) => {
      const $target = $(e.currentTarget);
      const $link = $target.find("a.stretched-link");
      const href = $link.attr("href");
      if (!href) {
        return;
      }

      const customEventParameter = $link.data("datalayer-custom-event-parameter");
      dataLayer.push({
        event: "donck_product_detail_modal",
        customEventParameter: customEventParameter,
      });
      const modalSelector = $target.data("modal-selector");
      const contentSelector = $target.data("content-selector");
      this.loadModal(href, modalSelector, contentSelector, e);
    });
  }

  private loadModal(href: string, modalSelector: string, contentSelector, e: MouseEventBase) {
    const modalElement = document.querySelector(modalSelector);
    if (!modalElement) return;
    const modal = new Modal(modalElement);
    e.preventDefault();
    e.stopPropagation();
    axios.get(href).then((response) => {
      const page = response.data as string;
      const $page = $("<div/>").append(page);
      const html = $page.find(contentSelector).html();
      $(modalElement).find(".modal-body").html(html);
      modal.show();
      CarbonIcons.init(modalElement);
      Slider.init(modalElement);
      QuantitySelector.initElement(modalElement);
    });
  }
}

new OpenLinkInModal();

﻿$("body").on("click", ".js-weeklink", (e) => {
  const cat = $('[name="cat"]')
    .filter((i, el) => !!$(el).val())
    .first()
    .val();
  const catidSearch = $('[name="CurrentCatalogId"]').val();
  const catidDocs = $("#CurrentCatalogIdForDocsView").val();

  const catid = cat ?? catidSearch ?? catidDocs ?? null;
  const base = $(e.currentTarget).attr("href");

  if (!!catid && !!base) {
    e.preventDefault();
    e.stopPropagation();
    document.location.href = `${base}?catalogId=${catid}`;
  }
});

﻿import $ from "jquery";
import { submitForm } from "../components/auto-submits";

const $body = $("body");

$body.on("click", ".js-check-onclick", (e) => {
  const $this = $(e.currentTarget);

  // uncheck all other sibling radio
  $this.siblings().children('input[type="radio"]').prop("checked", false);

  // check radio
  $this.children('input[type="radio"]').prop("checked", true);

  // toggle checkbox
  const currentCheckState = $this.children('input[type="checkbox"]').prop("checked");
  $this.children('input[type="checkbox"]').prop("checked", !currentCheckState);
});

$body.on("click", ".js-toggle-class-onclick", (e) => {
  const $this = $(e.currentTarget);
  const toggleClass = $this.data("toggle-class-onclick");
  $this.addClass(toggleClass);
  $this.siblings(`.${toggleClass}`).removeClass(toggleClass);
});

$body.on("focus", ".js-input-select-on-focus", (e) => {
  const target = e.currentTarget;
  if (!target) return;
  target.select();
});

$body.on("input", ".js-enable-on-input", (e) => {
  const $target = $(e.currentTarget);
  const selector = $target.data("enable-selector");
  $(selector).prop("disabled", false).removeClass("disabled");
});

$(".js-input-focus").trigger("focus");

// clear (input/select/textarea) value(s):
$body.on("click", ".js-clear-value", (e) => {
  const $e = $(e.currentTarget);
  const selector = $e.data("clear-value-selector");
  const $elementsToClear = $(selector);
  $elementsToClear.val("").trigger("input").trigger("change");
  if ($e.hasClass("js-clear-value--auto-submit")) {
    submitForm($e, 0);
  }
});

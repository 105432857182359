import debounce from "lodash-es/debounce";
import axios from "axios";
import { handleAxiosError } from "../http/axios-handlers";
import Autocomplete, { AutocompleteOptions } from "./bootstrap-5-autocomplete";
import CatalogIdProvider from "../utils/catalog-id-provider";
import { memoizeAsync, MemoizeCacheType } from "../utils/memoize";

interface SearchSuggestion {
  /**
   * Text to show in the dropdown.
   */
  text: string;
  /**
   * URI to navigate to when the option is selected frmo the dropdown.
   */
  uri: string;
  isFromHistory: boolean;
}

interface Dictionary<TValue> {
  [Key: string]: TValue;
}

export default class SearchAutocomplete {
  private $searchInputSelector: JQuery<HTMLElement>;
  private previousSuggestionsJson: string;
  private memoizedApiCall = memoizeAsync(
    (st: string, cat: string) =>
      axios.get(`/search-suggestions?query=${st}&catalogId=${cat}`).then((response) => response.data),
    MemoizeCacheType.Session
  );

  constructor(selector: string) {
    this.previousSuggestionsJson = "";
    this.$searchInputSelector = $(selector);
    let debounced = debounce(async (element: HTMLElement) => await this.showSearchSuggestions(element), 500);
    this.$searchInputSelector.on("input focus", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      debounced(e.target);
    });
  }

  private async showSearchSuggestions(el: HTMLElement) {
    const instance = this;
    const $el = $(el);
    const searchTerm = encodeURIComponent(("" + $el.val()).trim());

    let length = `${searchTerm}`.length;
    if (length != 0 && length < 3) {
      return;
    }

    const catalogId = CatalogIdProvider.getCurrentCatalogId();

    try {
      const suggestions = await this.getSearchSuggestions(searchTerm, catalogId ?? "0");
      const suggestionsJson = JSON.stringify(suggestions);
      if (this.previousSuggestionsJson === suggestionsJson) {
        return;
      }
      this.previousSuggestionsJson = suggestionsJson;
      this.processSearchSuggestions(suggestions, el);
    } catch (e) {
      handleAxiosError(e);
    }
  }

  private async getSearchSuggestions(searchTerm: string, catalogId: string) {
    if (searchTerm.length < 3) {
      return [];
    }

    return await this.memoizedApiCall(searchTerm, catalogId);
  }

  private processSearchSuggestions(data: any, el: HTMLElement) {
    if (!data) {
      return;
    }

    if (!data.textSuggestions) {
      return;
    }

    const dictionary: any = {};
    const suggestions = <any>data.customSuggestions;
    for (const suggestion of suggestions) {
      dictionary[suggestion.text] = suggestion.uri;
    }
    let options: AutocompleteOptions = {
      source: () => {
        return Promise.resolve(dictionary);
      },
      onSelectItem: (item, element) => {
        if (item?.value) {
          location.href = `${window.location.origin}/${item.value}`;
          return;
        }
        this.setSearchTextAndSubmit(item?.label);
      },
      highlightClass: "text-success",
      threshold: 0,
      maximumItems: 10,
    };
    const ac = new Autocomplete(el, options, el.parentElement);
    ac.open();
  }

  /**
   * Instead of using URI, set search text on form and submit form
   * such that all of the search form logic can be followed
   * (i.e. get all brands that match a search term, even if a brand
   * has already been filtered on).
   * @param searchText
   */
  private setSearchTextAndSubmit(searchText: string) {
    this.$searchInputSelector.val(searchText);
    this.$searchInputSelector.parents("form").trigger("submit");
  }
}

new SearchAutocomplete(".js-search-autocomplete");

﻿import { Toast } from "bootstrap";

export interface ToasterContent {
  title: string;
  subtitle: string;
  body: string;
  autohide?: boolean;
  colorScheme?: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark";
}

export default class Toaster {
  static Toast(content: ToasterContent) {
    const { title, subtitle, body, autohide, colorScheme } = content;
    const extraClass = colorScheme ? `text-bg-${colorScheme}` : "";
    const html = `
<div class="toast border-0 ${extraClass}" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
      <strong class="me-auto">${title}</strong>
      <small>${subtitle}</small>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
      ${body}
    </div>
</div>`;

    let $toastContainer = Toaster.getToastContainer();

    const $element = $(html);
    $toastContainer.append($element);
    const element = $element.get().first();
    if (!element) {
      return;
    }

    const toast = new Toast(element, { autohide: !!autohide });
    toast.show();
  }

  private static getToastContainer() {
    // noinspection JSJQueryEfficiency
    let toastContainer = $(".toast-container");
    if (toastContainer.length === 0) {
      $("body").append(`<div class="toast-container position-fixed top-0 end-0 p-3"></div>`);
      return $(".toast-container");
    }
    return toastContainer;
  }
}
